import React, { useContext } from 'react';

import { FormContext } from '../context';

export default function Radio({ field }) {
  const { values, handleChange, getFieldId } = useContext(FormContext);
  const { label, required, options } = field;
  return (
    <div className="radio" style={{ paddingBottom: `0.625rem` }}>
      {label && (
        <span className="label">
          <strong
            style={{
              display: `block`,
              marginBottom: `1.25rem`,
            }}
          >
            {label} {required ? <span>*</span> : ``}
          </strong>
        </span>
      )}
      <div
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          alignItems: `center`,
          justifyContent: `space-between`,
        }}
      >
        {options.map(radio => {
          const optionId = getFieldId(radio.option);
          const optionProps = {
            type: field.inputType,
            name: field.id,
            id: optionId,
            value: radio.option,
            className: field.class,
            title: radio.option,
            'aria-label': radio.option,
            onClick: handleChange,
            onChange: handleChange,
            required,
          };
          return (
            <label
              key={optionId}
              htmlFor={optionId}
              className={`checkbox${
                values[field.id] === radio.option ? ' active' : ''
              }`}
            >
              <input
                {...optionProps}
                style={{
                  display: `none`,
                  width: `auto`,
                }}
              />
              {radio.option}
            </label>
          );
        })}
      </div>
    </div>
  );
}
